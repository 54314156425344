import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BaseBanner from 'components/banners/base-banner';

const ALT = 'Total Loyalty, digital loyalty made easy for everyone.';

const FaqBanner = () => {
  const data = useStaticQuery(graphql`
   query {
     lg: file(relativePath: { eq: "faq/large_desktop.png" }) {
       childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     md: file(relativePath: { eq: "faq/desktop.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     sm: file(relativePath: { eq: "faq/desktop.png" }) {
       childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
     xs: file(relativePath: { eq: "faq/mobile.png" }) {
       childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, formats: [AUTO,WEBP], placeholder:TRACED_SVG)
        }
     }
   }
 `);

  return (<BaseBanner alt={ALT} data={data}/>);
};

export default FaqBanner;
